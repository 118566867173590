// SERVICES

.services-list {
	display: block;
	background-color: transparent;

	@include breakpoint(medium) {
		margin-top: rem-calc(60);
	}

	@include breakpoint(large) {
		margin-top: rem-calc(100);
	}

	.accordion-item {
		display: block;
		margin-bottom: 1em;
		background-color: $white;
		@include box-shadow(4px);
		// @include transition(0.4s);

		.accordion-title {
			@include breakpoint(large) {
				padding-left: rem-calc(30);
				padding-right: rem-calc(30);
			}

			h4 {
				margin: 0;
				padding-right: rem-calc(40);
				@include transition(0.4s);
			}
		}

		&.is-active {
			.accordion-title {
				h4 {
					@include text-h3;
				}
			}
		}

		.accordion-content {
			@include breakpoint(large) {
				padding-left: rem-calc(30);
				padding-right: rem-calc(30);
			}

			h2, h3, h4, h5 {
				color: $black;
			}

			ul, ol {
				margin-bottom: 1.25em;
    			list-style: initial;
			}
		}
	}

	.thum {
		@include ratio1-1;
		margin-top: .45em;
		margin-bottom: 2em;
	}
}