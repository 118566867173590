// PRODUCTS DETAIL


.product-detail {

	.page-title {
		height: auto;
		padding-top: 0;
		padding-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 0;

		@include breakpoint(medium) {
		}
	}

	.left-column {
		display: block;
		padding-top: .5em;

		@include breakpoint(medium) {
			position: absolute;
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}

	.right-column {
		position: relative;

		@include breakpoint(medium) {
			border-left: 1px $blue-lighter-color solid;
			padding-top: rem-calc(30);
			padding-bottom: rem-calc(30);
			padding-left: rem-calc(85) / 2 + rem-calc(10);
		}
	}

	.back {
		display: block;
		line-height: 2em;
		left: 0;

		.literal {
			font-size: $text-small;
		}

		&:hover {
			color: $blue-color;
		}

		.icon {
			position: absolute;
			left: 0;
			margin-left: .25em;
			color: $black;
			font-size: 2em;
		}

		@include breakpoint(medium) {
			position: absolute;
			top: 50%;
			margin-top: -1em;

			.literal {
				font-size: $text-normal;
			}

			.icon {
				position: relative;
				float: left;
				margin: 0;
			}
		}
	}

	.product-type-icon {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: rem-calc(75) / -2;
		margin-bottom: rem-calc(75) * -1;
		width: rem-calc(75);
		height: rem-calc(75);
		background-color: $white;
		@include border-radius(50%);
		z-index: 10;

		span {
			font-size: rem-calc(75);
			color: $blue-color;
		}

		@include breakpoint(medium) {
			left: 0;
			bottom: auto;
			top: 50%;
			margin-left: rem-calc(85) / -2;
			margin-top: rem-calc(85) / -2;
			width: rem-calc(85);
			height: rem-calc(85);

			span {
				font-size: rem-calc(85);
			}
		}
	}

	.products-catalog {
		margin-top: rem-calc(15);

		@include breakpoint(medium) {
			text-align: left;

			.button {
				width: auto;
			}
		}

		@include breakpoint(large) {
			margin-top: 0;
			margin-bottom: rem-calc(15);
			
			.button {
				width: 100%;
			}
		}
	}

	.product-description {
		h1, h2, h3, h4, h5, h6 {
			display: block;
			color: $black;
			padding-top: 1em;
		}
	}

	.product-description,
	.product-features,
	.product-specifications {
		h1, h2, h3, h4, h5, h6 {
			display: block;
			color: $black;
			padding-top: 1em;

			&:first-child {
				padding-top: 0;
			}
		}
	}
}




















