@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "{{root}}assets/fonts/" !default;
$slick-font-family: "mef" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: $blue-color !default;
$slick-dot-color: $blue-light-color !default;
$slick-dot-color-active: $blue-color !default;
$slick-prev-character: $mef-left !default;
$slick-next-character: $mef-right !default;
$slick-dot-character: $mef-circle !default;
$slick-prev-next-size: rem-calc(50) !default;
$slick-dot-size: rem-calc(15) !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.5 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: $slick-prev-next-size;
	width: $slick-prev-next-size;
	line-height: $slick-prev-next-size;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
    z-index: 100;

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;

		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}

	&:before {
		font-family: $slick-font-family;
		font-size: $slick-prev-next-size;
		line-height: $slick-prev-next-size;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	left: 0;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: 0;
	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 0;
}

.slick-dots {
	position: absolute;
	display: block;
	bottom: 0;
	list-style: none;
	text-align: center;
	margin: 0 0 -2em 0;
	padding: 0;
	width: 100%;
	line-height: 0;
	z-index: 101;

	li {
		position: relative;
		display: inline-block;
		height: $slick-dot-size;
		width: $slick-dot-size;
		margin: 0 .25em;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: $slick-dot-size;
			width: $slick-dot-size;
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 0;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: $slick-dot-size;
				height: $slick-dot-size;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: $slick-dot-size;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}


// Color Variations

.bkg-blue {
	.slick-prev,
	.slick-next {
		&:before {
			color: $blue-lighter-color;
		}
	}

	.slick-dots {
		li {
			button {
				&:before {
					color: $blue-lighter-color;
				}
			}
			&.slick-active button:before {
				color: $white;
			}
		}
	}
}









