// Icon Link


.icon-link {
	position: relative;
	float: right;
	margin: 0;
	color: $blue-light-color;
	text-align: center;

	&:hover {
		color: $white;
	}

	@include breakpoint(rem-calc(1080)) {
		margin: rem-calc(24) rem-calc(15) 0 0;
		color: $blue-color;

		&:hover {
			color: $anchor-color-hover;
		}
	}

	.icon {
		display: block;
		font-size: rem-calc(33);
		height: rem-calc(33);
	}

	.lan-label {
		display: none;

		@include breakpoint(rem-calc(1080)) {
			display: block;
			margin-top: rem-calc(-1);
			font-size: rem-calc(12);
		}
	}
}