// SIMILAR PRODUCTS LIST


.similar-products {
	h4.title {
		padding-top: 1em;

		@include breakpoint(large) {
			padding-top: 0;
			padding-bottom: .5em;
		}
	}

	@include breakpoint(medium only) {
		@include clearfix;
		padding-bottom: $spacer-normal;

		.item {
			padding: $global-margin $global-margin 0 $global-margin;
			float: left;
			width: 50%;

			&:nth-child(odd){
				padding-left: 0;
			}

			&:nth-child(even){
				padding-right: 0;
			}
		}
	}
}