// MAP


.map-wrapper {
	position: relative;
	@include ratio1-1;
	margin-top: rem-calc(14);

	@include breakpoint(medium) {
		@include ratio3-2;
	}

	@include breakpoint(large) {
		@include ratio1-1;
	}

	.map {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}