// ICON SET - INNER PATH

// Inner path align
[class^="mef-"], [class*=" mef-"] {
	[class*="path"] {
		float: left;
	}
}


// Custom
[aria-expanded="true"] {
	.mef-menu {
		&:before {
			content: $mef-cross;
		}
	}
}


// Custom general styles
.accordion-title {
	&:before {
		@include mef-font-face;
	}
}


// Accordions
.accordion-title {
	&:before {
		content: $mef-bottom;
		font-size: 2em;
		color: $black;
	}

	.is-active > &:before {
		content: $mef-top;
	}
}

