// BODY


body {
	background-color: lighten($blue-lighter-color, 10%);
	cursor: default;
	min-width: rem-calc(320);

	&.no-scroll {
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}