@import "icon-set-var";

@mixin mef-font-face() {
    font-family: 'mef';
    src:    url('#{$icomoon-font-path}/mef.eot?dag3l3');
    src:    url('#{$icomoon-font-path}/mef.eot?dag3l3#iefix') format('embedded-opentype'),
            url('#{$icomoon-font-path}/mef.ttf?dag3l3') format('truetype'),
            url('#{$icomoon-font-path}/mef.woff?dag3l3') format('woff'),
            url('#{$icomoon-font-path}/mef.svg?dag3l3#mef') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    @include mef-font-face;
}

[class^="mef-"], [class*=" mef-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mef' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mef-mw {
  &:before {
    content: $mef-mw;
  }
}
.mef-mef-full {
  &:before {
    content: $mef-mef-full;
  }
}
.mef-mef {
  &:before {
    content: $mef-mef;
  }
}
.mef-mef-color .path1 {
  &:before {
    content: $mef-mef-color-path1;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-color .path2 {
  &:before {
    content: $mef-mef-color-path2;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-color .path3 {
  &:before {
    content: $mef-mef-color-path3;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path1 {
  &:before {
    content: $mef-mef-full-color-path1;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path2 {
  &:before {
    content: $mef-mef-full-color-path2;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path3 {
  &:before {
    content: $mef-mef-full-color-path3;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path4 {
  &:before {
    content: $mef-mef-full-color-path4;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path5 {
  &:before {
    content: $mef-mef-full-color-path5;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path6 {
  &:before {
    content: $mef-mef-full-color-path6;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path7 {
  &:before {
    content: $mef-mef-full-color-path7;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path8 {
  &:before {
    content: $mef-mef-full-color-path8;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path9 {
  &:before {
    content: $mef-mef-full-color-path9;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path10 {
  &:before {
    content: $mef-mef-full-color-path10;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path11 {
  &:before {
    content: $mef-mef-full-color-path11;  
    margin-left: -1em;  
    color: rgb(79, 134, 160);
  }
}
.mef-mef-full-color .path12 {
  &:before {
    content: $mef-mef-full-color-path12;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path13 {
  &:before {
    content: $mef-mef-full-color-path13;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path14 {
  &:before {
    content: $mef-mef-full-color-path14;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path15 {
  &:before {
    content: $mef-mef-full-color-path15;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path16 {
  &:before {
    content: $mef-mef-full-color-path16;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path17 {
  &:before {
    content: $mef-mef-full-color-path17;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path18 {
  &:before {
    content: $mef-mef-full-color-path18;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path19 {
  &:before {
    content: $mef-mef-full-color-path19;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path20 {
  &:before {
    content: $mef-mef-full-color-path20;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path21 {
  &:before {
    content: $mef-mef-full-color-path21;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path22 {
  &:before {
    content: $mef-mef-full-color-path22;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path23 {
  &:before {
    content: $mef-mef-full-color-path23;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path24 {
  &:before {
    content: $mef-mef-full-color-path24;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path25 {
  &:before {
    content: $mef-mef-full-color-path25;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path26 {
  &:before {
    content: $mef-mef-full-color-path26;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path27 {
  &:before {
    content: $mef-mef-full-color-path27;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-mef-full-color .path28 {
  &:before {
    content: $mef-mef-full-color-path28;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-arrow-down {
  &:before {
    content: $mef-arrow-down;
  }
}
.mef-arrow-up {
  &:before {
    content: $mef-arrow-up;
  }
}
.mef-menu {
  &:before {
    content: $mef-menu;
  }
}
.mef-bottom {
  &:before {
    content: $mef-bottom;
  }
}
.mef-top {
  &:before {
    content: $mef-top;
  }
}
.mef-right {
  &:before {
    content: $mef-right;
  }
}
.mef-left {
  &:before {
    content: $mef-left;
  }
}
.mef-plus {
  &:before {
    content: $mef-plus;
  }
}
.mef-cross {
  &:before {
    content: $mef-cross;
  }
}
.mef-med {
  &:before {
    content: $mef-med;
  }
}
.mef-vet {
  &:before {
    content: $mef-vet;
  }
}
.mef-med-vet {
  &:before {
    content: $mef-med-vet;
  }
}
.mef-facebook {
  &:before {
    content: $mef-facebook;
  }
}
.mef-twitter {
  &:before {
    content: $mef-twitter;
  }
}
.mef-linkedin {
  &:before {
    content: $mef-linkedin;
  }
}
.mef-google {
  &:before {
    content: $mef-google;
  }
}
.mef-youtube {
  &:before {
    content: $mef-youtube;
  }
}
.mef-instagram {
  &:before {
    content: $mef-instagram;
  }
}
.mef-pinterest {
  &:before {
    content: $mef-pinterest;
  }
}
.mef-wordpress {
  &:before {
    content: $mef-wordpress;
  }
}
.mef-yelp {
  &:before {
    content: $mef-yelp;
  }
}
.mef-pdf {
  &:before {
    content: $mef-pdf;
  }
}
.mef-es .path1 {
  &:before {
    content: $mef-es-path1;  
    color: rgb(249, 225, 0);
  }
}
.mef-es .path2 {
  &:before {
    content: $mef-es-path2;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-es .path3 {
  &:before {
    content: $mef-es-path3;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path1 {
  &:before {
    content: $mef-en-path1;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path2 {
  &:before {
    content: $mef-en-path2;  
    margin-left: -1em;  
    color: rgb(29, 41, 144);
  }
}
.mef-en .path3 {
  &:before {
    content: $mef-en-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path4 {
  &:before {
    content: $mef-en-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path5 {
  &:before {
    content: $mef-en-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path6 {
  &:before {
    content: $mef-en-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path7 {
  &:before {
    content: $mef-en-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path8 {
  &:before {
    content: $mef-en-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path9 {
  &:before {
    content: $mef-en-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path10 {
  &:before {
    content: $mef-en-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.mef-en .path11 {
  &:before {
    content: $mef-en-path11;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path12 {
  &:before {
    content: $mef-en-path12;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path13 {
  &:before {
    content: $mef-en-path13;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path14 {
  &:before {
    content: $mef-en-path14;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path15 {
  &:before {
    content: $mef-en-path15;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path16 {
  &:before {
    content: $mef-en-path16;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path17 {
  &:before {
    content: $mef-en-path17;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-en .path18 {
  &:before {
    content: $mef-en-path18;  
    margin-left: -1em;  
    color: rgb(221, 0, 49);
  }
}
.mef-circle {
  &:before {
    content: $mef-circle;
  }
}
.mef-circle-small {
  &:before {
    content: $mef-circle-small;
  }
}
.mef-ring {
  &:before {
    content: $mef-ring;
  }
}
.mef-ring-small {
  &:before {
    content: $mef-ring-small;
  }
}
.mef-search {
  &:before {
    content: $mef-search;
  }
}
.mef-play {
  &:before {
    content: $mef-play;
  }
}
.mef-envelope {
  &:before {
    content: $mef-envelope;
  }
}
