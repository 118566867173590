// ITEM BLOCK



.item-block {
	position: relative;
	display: block;
	background-color: $white;
	margin-bottom: $grid-column-gutter;
	text-align: left;
	@include box-shadow(4px);

	.slick-slider & {
		margin-bottom: 0;
	}

	.thum {
		@include ratio23-8;
	}

	.info {
		position: relative;
		display: block;
		padding: rem-calc(15);
	}

	.icon {
		position: absolute;
		right: 0;
		margin-top: rem-calc(12); // 5
		font-size: rem-calc(30); // 44
		color: $black;
	}

	.title,
	.desc {
		display: block;
		margin: 0;
		padding: 0;
		width: 100%;
		overflow: hidden;
	}

	.title {
		color: $black;
		font-weight: bold;
		height: rem-calc(50); 
		padding-right: 1em;
	}

	.desc {
		display: none;
		color: $dark-gray;
		height: rem-calc(25);
	}
}

