// ABOUT US


.about {
	.page-subtitle {
		text-align: center;
		color: $black;

		@include breakpoint(medium) {
			text-align: left;
		}
	}
}