// BROCHURES



.brochures {
	@include clearfix;

	@include breakpoint(medium) {
		padding-bottom: $spacer-normal;
	}

	.item {
		padding: $global-margin $global-margin 0 $global-margin;

		@include breakpoint(medium) {
			float: left;
			width: 50%;
		}

		@include breakpoint(large) {
			width: 33.3333%;
		}
	}
}