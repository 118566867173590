// ADVERTISING


#ad {
	position: relative;
	display: block;
	height: auto;
	@include box-sizing-reset;
	padding: 0;
	border-top: $spacer-small solid $white;
	border-bottom: $spacer-small solid $white;
	overflow: hidden;
	max-height: 250px;
	z-index: 10;
	background-color: $white;

	.item {
		position: relative;
		float: left;
	}

	.item {
		text-align: center;
		padding: 0 $spacer-small/2;
		width: 300px;
		height: auto;
		max-height: 250px;

		a {
			background: #fff url(../img/ajax-loader.gif) center center no-repeat;			
		}

		a, img {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
		}
	}
}