// PRODUCT CATALOG


.products-catalog {
	text-align: center;

	.button {
		width: 100%;
		margin-left: 0;
		margin-right: 0;

		.icon {
			color: $red-color;
			display: inline-block;
			font-size: 2em;
			line-height: 0;

			&:before {
				float: left;
				margin-top: -.2em;
			}
		}
	}
}

