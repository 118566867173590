$icomoon-font-path: "../fonts" !default;

$mef-mw: "\e900";
$mef-mef-full: "\e901";
$mef-mef: "\e902";
$mef-mef-color-path1: "\e903";
$mef-mef-color-path2: "\e904";
$mef-mef-color-path3: "\e905";
$mef-mef-full-color-path1: "\e906";
$mef-mef-full-color-path2: "\e907";
$mef-mef-full-color-path3: "\e908";
$mef-mef-full-color-path4: "\e909";
$mef-mef-full-color-path5: "\e90a";
$mef-mef-full-color-path6: "\e90b";
$mef-mef-full-color-path7: "\e90c";
$mef-mef-full-color-path8: "\e90d";
$mef-mef-full-color-path9: "\e90e";
$mef-mef-full-color-path10: "\e90f";
$mef-mef-full-color-path11: "\e910";
$mef-mef-full-color-path12: "\e911";
$mef-mef-full-color-path13: "\e912";
$mef-mef-full-color-path14: "\e913";
$mef-mef-full-color-path15: "\e914";
$mef-mef-full-color-path16: "\e915";
$mef-mef-full-color-path17: "\e916";
$mef-mef-full-color-path18: "\e917";
$mef-mef-full-color-path19: "\e918";
$mef-mef-full-color-path20: "\e919";
$mef-mef-full-color-path21: "\e91a";
$mef-mef-full-color-path22: "\e91b";
$mef-mef-full-color-path23: "\e91c";
$mef-mef-full-color-path24: "\e91d";
$mef-mef-full-color-path25: "\e91e";
$mef-mef-full-color-path26: "\e91f";
$mef-mef-full-color-path27: "\e920";
$mef-mef-full-color-path28: "\e921";
$mef-arrow-down: "\e922";
$mef-arrow-up: "\e923";
$mef-menu: "\e924";
$mef-bottom: "\e925";
$mef-top: "\e926";
$mef-right: "\e927";
$mef-left: "\e928";
$mef-plus: "\e929";
$mef-cross: "\e92a";
$mef-med: "\e92b";
$mef-vet: "\e92c";
$mef-med-vet: "\e92d";
$mef-facebook: "\e930";
$mef-twitter: "\e931";
$mef-linkedin: "\e932";
$mef-google: "\e933";
$mef-youtube: "\e934";
$mef-instagram: "\e935";
$mef-pinterest: "\e936";
$mef-wordpress: "\e937";
$mef-yelp: "\e938";
$mef-pdf: "\e939";
$mef-es-path1: "\e93a";
$mef-es-path2: "\e93b";
$mef-es-path3: "\e93c";
$mef-en-path1: "\e93d";
$mef-en-path2: "\e93e";
$mef-en-path3: "\e93f";
$mef-en-path4: "\e940";
$mef-en-path5: "\e941";
$mef-en-path6: "\e942";
$mef-en-path7: "\e943";
$mef-en-path8: "\e944";
$mef-en-path9: "\e945";
$mef-en-path10: "\e946";
$mef-en-path11: "\e947";
$mef-en-path12: "\e948";
$mef-en-path13: "\e949";
$mef-en-path14: "\e94a";
$mef-en-path15: "\e94b";
$mef-en-path16: "\e94c";
$mef-en-path17: "\e94d";
$mef-en-path18: "\e94e";
$mef-circle: "\e94f";
$mef-circle-small: "\e950";
$mef-ring: "\e951";
$mef-ring-small: "\e952";
$mef-search: "\e92e";
$mef-play: "\e92f";
$mef-envelope: "\e953";

