// Video


#video {
	position: relative;
	display: inline-block;

	video {
		width: auto;
		height: auto;
		max-width: 100%;
		box-shadow: $mfp-shadow;
	}

	.mfp-bottom-bar {
		margin-top: rem-calc(25);
	}

}
