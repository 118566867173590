// DEFAULT LIST


.default-list {
	list-style: none;
	margin: 0;
	padding: 0;

	.thum-small {
		width: rem-calc(80);
		border: 1px solid $medium-gray;
	}

	.info {
		min-height: rem-calc(80);

		.title {
			margin-bottom: rem-calc(4);
		}

		p {
			margin: 0;

			&.desc {
				color: $dark-gray;
			}
		}
	}
}


.table-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: table;
	table-layout: fixed;

	li {
		float: none;
		display: table-cell;
		padding: 0 rem-calc(15);
		vertical-align: top;
		border-left: 4px solid $blue-lighter-color;
	}
}

.table-list--2 {
	li {
		width: 50%;
	}
}

.table-list--3 {
	li {
		width: 33.333%;
	}
}

.table-list--4 {
	li {
		width: 25%;
	}
}

.table-list--5 {
	li {
		width: 20%;
	}
}

.table-list--6 {
	li {
		width: 16.666%;
	}
}

@include breakpoint(800px down) {
	.table-list {
		li {
			display: inline-block;
			margin-bottom: rem-calc(20);
		}
	}

	.table-list--4,
	.table-list--5,
	.table-list--6 {
		li {
			width: 50%;
		}
	}
}

@include breakpoint(480px down) {
	.table-list--2,
	.table-list--3,
	.table-list--4,
	.table-list--5,
	.table-list--6 {
		li {
			width: 100%;
		}
	}
}







