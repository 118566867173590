// MENU MAIN


.header-menu {
	.menu-main {
		a {
			font-weight: bold;
		}

		@include breakpoint(rem-calc(1080)) {
			margin-right: rem-calc(10) * -1;

			li {
				display: inline-block;
				width: auto;
			}

			a {
				line-height: rem-calc(94);
				font-weight: normal;
				padding: 0 rem-calc(10);
			}
		}
	}
}





















