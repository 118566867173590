// HEADER LOGO


.header-logo {
	display: inline-block;
	top: 0;
	line-height: 0;
	z-index: 120;

	@include breakpoint(rem-calc(1080)) {
		position: absolute;
		left: rem-calc(20);
	}

	.icon {
		margin: rem-calc(17) 0 0 0;
		font-size: rem-calc(110);
		color: $white;
		padding: 0;
		display: block;
		line-height: 0;
	}

	.mef-mef-full {
		display: block;
	}

	.mef-mef-full-color {
		display: none;
	}

	@include breakpoint(rem-calc(1080)) {
		.icon {
			float: left;
			margin-top: rem-calc(14);
		}

		.icon {
			line-height: .45em;
			font-size: rem-calc(160);
		}

		// .icon-only {
		// 	line-height: 1em;
		// 	font-size: rem-calc(70);
		// }

		.mef-mef-full {
			display: none;
		}

		.mef-mef-full-color {
			display: block;
		}
	}
}
