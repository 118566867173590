// Tops Request Quote and request Parts Buttons


.quote-and-parts {
	overflow: hidden;
	width: 100%;
	left: 0;
	margin-bottom: $spacer-normal;
	z-index: 10;

	.button {
		width: 48%;
		top: 0;
		margin: 0;
		padding-left: 0;
		padding-right: 0;
		
		&:first-child {
			float: left;
			left: 0;
			margin-left: 0;
			@include border-top-left-radius(0);
			@include border-bottom-left-radius(0);
		}
		
		&:last-child {
			float: right;
			right: 0;
			margin-right: 0;
			@include border-top-right-radius(0);
			@include border-bottom-right-radius(0);
		}
	}

	@include breakpoint(medium) {
		float: right;
		width: auto;
		@include clearfix;

		.button {
			width: auto;
			top: 0;
			margin: .5em 0 0 $global-margin/2;
			padding: $button-padding;
			
			&:first-child,
			&:last-child {
				float: none;
				left: auto;
				right: auto;
				@include border-radius(40px);
			}
		}
	}
}

.product-detail {
	.quote-and-parts {
		margin-top: 3em;
		
		@include breakpoint(medium) {
			float: none;
			display: block;
			text-align: center;
		}

		@include breakpoint(large) {
			float: right;
			margin-top: 0;
			@include clearfix;
		}
	}
}


