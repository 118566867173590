// OFF-CANVAS


.header-menu {
	background-color: transparent;

	@include breakpoint(rem-calc(1080)) {
		position: absolute;
		left: auto !important;
		right: $grid-column-gutter !important;
		top: 0 !important;
		z-index: 100 !important;
		width: auto !important;
	}
}

.off-canvas {
	overflow: visible;
}

.off-canvas-content {
	@include box-shadow-reset;
}






