// Newsletter


.newsletter {
	position: absolute;
	z-index: 110;
	top: 0;
	right: 0;
	margin: 0;
	margin-right: rem-calc(40); 
	left: auto;
	padding: rem-calc(8);
	height: rem-calc(50);

	@include breakpoint(small) {
		margin-right: rem-calc(45); 
	}

	@include breakpoint(rem-calc(1080)) {
		margin-right: rem-calc(52); 
	}
}


.newsletter-form {
	display: none;
	
	&.active {
		position: absolute;
		background-color: darken($blue-color, 15);
		display: block;
		width: 100%;
		min-width: rem-calc(320);
		height: auto;
		top: rem-calc(50);
		right: 0;
		padding: rem-calc(20) 0 rem-calc(20) 0;
		text-align: left;
		z-index: 1000;
		@include clearfix;

		@include breakpoint(rem-calc(1080)) {
			top: rem-calc(99);
			width: rem-calc(600);
		}

		.form-title {
			padding: 0 rem-calc(20);
		}

		.mef-arrow-down {
			position: absolute;
			top: 0;
			right: rem-calc(54);
			margin-top: rem-calc(-12);
			font-size: 2em;
			color: $blue-color;

			@include breakpoint(rem-calc(1080)) {
				color: $white;
				right: rem-calc(88);
			}
		}

		.email-box {
			position: absolute;
			padding: 0 rem-calc(110) 0 rem-calc(15);
			width: 100%;
			z-index: 1;

			input {
				position: relative;
				width: 100%;
			}

			@include breakpoint(rem-calc(1080)) {
				padding-left: rem-calc(20);
			}
		}

		.button {
			position: relative;
			float: right;
			margin: 0 rem-calc(15) 0 0;
			padding: rem-calc(10) 0 rem-calc(11) 0;
			width: rem-calc(95);
			min-width: auto;
			z-index: 10; 
			@include border-top-left-radius(0);
			@include border-bottom-left-radius(0);

			@include breakpoint(rem-calc(1080)) {
			}
		}
	}
}





















