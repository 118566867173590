// FOOTER LOGO


.footer-logo {
	display: inline;

	.mef-mef-full {
		display: block;
		font-size: rem-calc(160);
		line-height: 0;	
		margin: .2em 0 .3em 0;
	}

	@include breakpoint(large) {
		margin: 3em 0 4em 0;

		.mef-mef-full {
			margin: 0;
		}
	}
}