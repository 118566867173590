// ABOUT US - GALLERY


.about-gallery {
	.section-title {
		position: relative;
		background-color: $blue-lighter-color;
		margin: 0;
		padding: 0;

		.svg {
			z-index: 10;
		}

		.triangle {
			fill: $blue-lighter-color;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		@include clearfix;

		.item {
			float: left;
			margin: 0;
			padding: 0;
			width: 50%;

			@include breakpoint(480px) {
				width: 33.333%;
			}

			@include breakpoint(medium) {
				width: 25%;
			}

			@include breakpoint(large) {
				width: 16.6666%;
			}

			.thum {
				@include ratio1-1;
			}
		}
	}
}