// MENU TOGGLE BUTTON


.menu-toggle {
	position: absolute;
	left: 0;
	top: 0;
	background-color: $alert-color;
	width: rem-calc(50);
	height: rem-calc(50);
	text-align: center;
	@include transition(.4s);
	z-index: 9001;

	&:hover {
		background-color: darken($alert-color, 15%);
	}
	.icon {
		font-size: 2em;
		line-height: rem-calc(50);
		color: $white;
	}

	@include breakpoint(rem-calc(1080)) {
		display: none;
	}
}



