// PAGE BACKGROUND


.off-canvas-content {
	background-color: $white;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

.products,
.services {
	.off-canvas-content {
		background-color: $blue-lighter-color;
	}
}

.off-canvas-content {
	.about & {
		background-image: url(../img/about-bkg-sm.jpg);
	}
}

@include breakpoint(medium) {
	.off-canvas-content {
		.products & {
			background-image: url(../img/products-bkg-lg.png);
		}

		.services & {
			background-image: url(../img/services-bkg-lg.png);
		}

		.about & {
			background-image: url(../img/about-bkg-md.jpg);
		}

		.sell & {
			background-image: url(../img/sell-bkg-md.jpg);
			background-size: auto 550px;
			background-position: center top;
		}

		.contact & {
			background-image: url(../img/contact-bkg-md.jpg);
			background-size: auto;
			background-position: left top;
		}
	}
}

@include breakpoint(large) {
	.off-canvas-content {
		.about & {
			background-image: url(../img/about-bkg-lg.jpg);
		}

		.financing & {
			background-image: url(../img/financing-bkg-lg.jpg);
			background-size: auto 550px;
			background-position: right top;
		}

		.sell & {
			background-image: url(../img/sell-bkg-lg.jpg);
			background-position: center top;
		}

		.quote & {
			background-image: url(../img/quote-bkg-lg.jpg);
			background-size: auto 320px;
			background-position: right top;
		}

		.parts & {
			background-image: url(../img/parts-bkg-lg.jpg);
			background-size: auto 400px;
			background-position: right top;
		}

		.contact & {
			background-image: url(../img/contact-bkg-lg.jpg);
			background-position: center top;
		}
	}
}
