////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color:                   $white !default;								// Color of overlay screen
$mfp-overlay-opacity:                 0.95 !default;								// Opacity of overlay screen
$mfp-shadow:                          0 0 rem-calc(15) rgba(0, 0, 0, 0.2) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              rem-calc(30) !default;						// Padding from left and from right side
$mfp-popup-padding-left-mobile:       rem-calc(15) !default;						// Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    9010 !default;								// Base z-index of popup

// controls
$mfp-include-arrows:                  true !default;								// Include styles for nav arrows
$mfp-controls-opacity:                1 !default;									// Opacity of controls
$mfp-controls-color:                  $blue-color !default;							// Color of controls
$mfp-controls-border-color:           $blue-color !default;							// Border color of controls
$mfp-inner-close-icon-color:          $blue-color !default;							// Color of close button when inside
$mfp-controls-text-color:             $blue-color !default;							// Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       $blue-color !default;							// Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;								// Enable Iframe-type popups
$mfp-iframe-padding-top:              15px !default;								// Iframe padding top
$mfp-iframe-background:               #000 !default;								// Background color of iframes
$mfp-iframe-max-width:                900px !default;								// Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;								// Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;								// Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               15px !default;								// Image padding top
$mfp-image-padding-bottom:            15px !default;								// Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;								// Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             $black !default;								// Caption title color
$mfp-caption-subtitle-color:          $black !default;								// Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;								// Hide content from browsers, but make it available for screen readers
