// PAGE FOOTER


.page-footer {
	background-color: $blue-color;
	padding-top: $grid-column-gutter;
	padding-bottom: $grid-column-gutter;
	font-size: $text-small;
	color: $white;
	text-align: center;

	a {
		color: $white;

		&:hover {
			color: $blue-lighter-color;
		}
	}

	.footer-menu {
		display: none;
	}


	@include breakpoint(large) {
		text-align: left;

		.footer-menu,
		.copyright {
			float: left;
		}

		.developer {
			float: right;
		}

		.footer-logo,
		.footer-menu,
		.copyright,
		.developer {
			display: block;
			margin-left: $grid-column-gutter / 2;
			margin-right: $grid-column-gutter / 2;
		}
	}
}