// LANG


.header-menu {
	.lan {
		position: relative;
		color: $blue-color;

		@include breakpoint(rem-calc(1080)) {
			[lang="en"] & {
				margin-right: rem-calc(122); 
			}

			[lang="es"] & {
				margin-right: rem-calc(102); 
			}
		}

		a {
			text-align: right;
			font-weight: normal;
		}

		.literal {
			float: left;
			font-weight: bold;
		}

		.mef-es,
		.mef-en {
			width: rem-calc(32);
			font-size: 2em;
			margin-bottom: -.32em;

			span {
				float: left;
			}
		}

		.lan-label {
			@include breakpoint(rem-calc(1080)) {
				position: absolute;
				top: rem-calc(25);
				left: 0;
				margin: 0;
				width: 100%;
				font-size: rem-calc(12);
				text-align: center;
			}
		}
	}
}


// Hide languaje icons
html {
	&[lang="en"] {
		.mef-en {
			display: none;
		}
		.mef-es {
			display: inline-block;
		}
	}

	&[lang="es"] {
		.mef-es {
			display: none;
		}
		.mef-en {
			display: inline-block;
		}
	}
}
