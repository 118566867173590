// INTRO


.intro {
	padding-top: $spacer-large;
	padding-bottom: $spacer-large;

	h1, h2, h3, h4, h5, h6 {
		color: $black;
	}

	p:last-child {
		margin: 0;
	}
}

.sell {
	.intro {
		padding-top: 0;
		padding-bottom: $spacer-large;

		@include breakpoint(large) {
			padding-bottom: $spacer-large * 2;
		}
	}
}

.index {
	.intro {
		text-align: center;

		.logo {
			padding-bottom: 1em;
			display: inline-block;
		}

		.icon {
			font-size: 15.6em;
			line-height: .35em;
			@include clearfix;
		}

		@include breakpoint(large) {
			text-align: left;

			.logo {
				float: left;
				padding-bottom: 1em;
				margin-right: rem-calc(30);
			}
		}
	}
}