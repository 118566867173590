// FOOTER MENU


.footer-menu {
	ul {
		float: left;
		list-style: none;
		margin: 0;
		padding: 0;
		margin-right: $grid-column-gutter;

		a {
			display: block;
			padding-bottom: .5em;
		}

		&.footer-menu--main {
			a {
				font-weight: bold;
			}
		}
	}
}

