// TITLE BAR


.title-bar {
	height: rem-calc(50);
	background-color: $blue-color;
	text-align: center;

	@include breakpoint(rem-calc(1080)) {
		height: rem-calc(100);
		background-color: $white;
		border-bottom: 1px $blue-lighter-color solid;
	}
}
