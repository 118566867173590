// PAGINATION


.pagination {
	text-align: center;
	padding: 0 0 $spacer-normal 0;

	@include breakpoint(medium) {
		padding-top: $spacer-normal;
	}


	a {
		display: inline-block;

		span {
			width: 2em;
			height: 2em;
			font-size: 2em;
			padding: .5em;
			background-color: $white;
			@include border-radius (50%);
			line-height: 2em;
		}
	}
}


[data-visible='false'] {
	position: absolute;
	//display: none;
	@include opacity(0);
	margin: -2000em 0 0 -2000em;
}


[data-visible='true'] {
	position: relative;
	//display: block;
	@include opacity(1);
	margin: auto;
}
