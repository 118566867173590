// GENERAL STYLES


// Bkg images
[data-bkg],
[bkgmulti] {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}


// Bkg color
.bkg-blue {
	background-color: $blue-color;
}

.bkg-blue-lighter {
	background-color: $blue-lighter-color;
}

.bkg-red {
	background-color: $red-color;
}

.bkg-white {
	background-color: $white;
}


// Transformaciones
.transition { @include transition(0.4s); }

.opacity 	{ @include opacity(1); }

a.opacity {
	&:hover { @include opacity(.5); }
}

.scale {
	@include scale(1);

	&:hover {
		@include scale(1.2);
	}
}


// List 
.alpha {
	list-style-type: lower-alpha; 
}


// Eliminar apariencia definida por el navegador en formularios
input, select, textarea { -webkit-appearance: none; }
input[type=checkbox] { -webkit-appearance: checkbox; }
input[type=radio] { -webkit-appearance: radio; }


// Texts
.section-title {
	text-align: center;
	color: $black;
}

// Links
* { outline: 0 none !important; }


// SGV Triangle Container
.svg {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}
