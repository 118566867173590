// TYPOGRAPHY


.h1 {
	@include text-h1;
}

.h2 {
	@include text-h2;
}

.h3 {
	@include text-h3;
}

.h4 {
	@include text-h4;
}

.h5 {
	@include text-h5;
}

.h6 {
	@include text-h6;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	line-height: normal;
}

.title {
	color: $black;
	line-height: normal;

	a & {
		color: inherit;
	}
}

.text-black {
	color: $black;
}

.text-blue {
	color: $blue-color;
}

.text-red {
	color: $red-color;
}

