// FOLLOW US


.follow-us {
	display: none;
	background-color: $blue-lighter-color;
	padding: $spacer-normal*1.5 0;
	text-align: center;

	@include breakpoint(large) {
		display: block;
		position: relative;
		padding: $spacer-large 0;
	}

	h3 {
		display: inline-block;
		margin: 0;
		font-size: $text-normal;
		font-weight: bold;
		color: $blue-color;

		span {
			position: relative;
			display: block;
			z-index: 2;
		}

		&:before {
			content: " ";
			position: relative;
			display: block;
			width: 100%;
			height: rem-calc(24);
			@include box-sizing-reset;
			padding: 0 rem-calc(20);
			margin-left: rem-calc(20) * -1;
			margin-bottom: rem-calc(24) * -1;
			background-color: $blue-lighter-color;
			z-index: 1;
		}

		&:after {
			content: " ";
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			margin-top: $spacer-large + 12;
			padding: 0;
			height: 1px;
			background-color: $blue-color;
			z-index: 0;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin: rem-calc(15) rem-calc(30) 0 rem-calc(30);

			a {
				display: block;

				span {
					display: block;
					font-size: 2.5em;
				}
			}
		}
	}
}


.index {
	.follow-us {
		display: block;
		background-color: $white;

		hr {
			display: none;
		}

		h3 {
			display: inline;
			@include text-h3;
			font-weight: normal;
			color: $black;

			span {
				display: inline;
			}

			&:before,
			&:after {
				display: none;
			}
		}

		ul {
			li {
				margin: rem-calc(7.5);

				@include breakpoint(large) {
					margin: rem-calc(15);
				}

				a {
					display: block;
					background-color: $blue-lighter-color;
					border: 1px $blue-light-color solid;
					@include border-radius(30%);

					span {
						display: block;
						font-size: 2.5em;
						padding: $grid-column-gutter / 1.85;
					}
				}
			}
		}
	}
}