// MODAL (REVEAL)


.reveal {
	text-align: center;

	.button {
		margin: 0 0 1em 0;
	}
}


[data-submit="success"] {
	.reveal {
		.content-success {
			display: block;
		}

		.content-error {
			display: none;
		}
	}
}


[data-submit="error"] {
	.reveal {
		.content-success {
			display: none;
		}

		.content-error {
			display: block;
		}
	}
}