// PRODUCTS FILTER


.row-products-filter {
	padding-bottom: $spacer-small;
}

.products-filter {
	.filter-item {
		position: relative;
		width: 100%;
		margin: 0 rem-calc(15) rem-calc(15) 0;
		@include border-radius(40px);
		background-color: $white;
		padding: 0 rem-calc(15) 0 0;

		.icon {
			position: absolute;
			top: rem-calc(3);
			left: rem-calc(3);

			span {
				font-size: 2.85em;
				color: $blue-color;
			}
		}

		&.type {
			padding-left: 1.65em;
		}

		&.no-icon {
			&.type {
				padding-left: 0;
			}
		}
	}	

	select {
		position: relative;
		display: block;
		border: 0;
		background-color: transparent;
		padding: 0 rem-calc(30);
		margin: 0;
		height: 50px;
		width: 100%;
		text-align: left;
	}
}