// PRODUCT GALLERY


.product-gallery {
	position: relative;
	display: block;
	width:100%;
	height: auto;
	z-index: 0;
	overflow: hidden;

	@include breakpoint(medium) {
		border-top: 1px $blue-lighter-color solid;
		height: 480px
	}

	@include breakpoint(large) {
		border-bottom: 1px $blue-lighter-color solid;
		margin-bottom: $spacer-large * 1.5 !important;
	}


	.item {
		position: relative;
		min-width: 100px;

		a {
			position: relative;
			display: block;
			background: #fff url(../img/ajax-loader.gif) center center no-repeat;
		}

		img {
			display: block;
			height: auto;

			@include breakpoint(medium) {
				height: 480px
			}
		}

		&.video {
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				font-size: 6em;
				margin: -.5em auto auto -.5em;
				color: $white;
				background-color: $blue-color;
				@include border-radius(50%);
			}
		}
	}
}

