// PAGE TITLE


.page-title {
	position: relative;
	height: rem-calc(50);
	background-color: $white;
	text-align: center;
	padding-top: .2em;
	margin-bottom: $spacer-small;

	@include breakpoint(medium) {
		text-align: left;
		padding-top: rem-calc(30);
		padding-bottom:  $spacer-large * 1.5;
		background-color: transparent;
	}

	@include breakpoint(large) {
		padding-bottom:  $spacer-large * 2;
	}
	
	h1 {
		margin: 0;
	}

	h1, h2, h3, h4, {
		color: $black;
	}
}