// SOCIAL



// Header Social Menu

.header-menu {
	.menu-social {
		border-top: 1px solid rgba( $blue-light-color, .25 );
		@include clearfix;

		li {
			float: left;
			width: 25%;
			text-align: center;
			border: 0;

			a {
				font-size: 2em;
				padding: 0;
			}
		}

		@include breakpoint(rem-calc(1080)) {
			display: none;
		}
	}
}