// BUTTONS


.button {
	@include border-radius(25px);
	white-space: nowrap;

	&.white-red {
		background-color: $white;
		color: $red-color;
	}

	&.white-blue {
		background-color: $white;
		color: $blue-color;

		.mef-pdf {
			color: $red-color;
		}
	}

	&.light-blue {
		background-color: $blue-light-color;
		color: $blue-color;
	}

	&.lighter-blue {
		background-color: $blue-lighter-color;
		color: $blue-color;
	}

	.icon {
		color: $red-color;
		display: inline-block;
		font-size: 2em;
		line-height: 0;

		&:before {
			float: left;
			margin-top: -.2em;
		}
	}
}

.button-group {
	.button {
		@include border-radius(0);

		&:first-child {
			@include border-top-left-radius(25px);
			@include border-bottom-left-radius(25px);
		}

		&:last-child {
			@include border-top-right-radius(25px);
			@include border-bottom-right-radius(25px);
		}
	}
}


// Addthis

.addthis {
	display: block;
	height: rem-calc(32);
	line-height: rem-calc(32);
	margin-bottom: $spacer-normal;
	text-align: center;

	@include breakpoint(large) {
		text-align: right;

		.literal {
			display:inline-block;
			text-align: right;
			line-height: rem-calc(32);
		}

		.addthis_sharing_toolbox {
			display:inline-block;
		}
	}

	.at-share-tbx-element .at-share-btn {
		margin: 0 .5em;

		@include breakpoint(large) {
			margin: 1em 0 0 .5em;
		}
	}
}







