// FEATURE PRODUCTS


.tabs {
	background-color: transparent;
	border: 0;

	.tabs-title {
		border: 0;
		text-align: center;
		width: 50%;
		
		a {
			background-color: $blue-color;
			color: $white;
			@include text-h5;

			&:hover {
				background-color: scale-color($blue-color, $lightness: -15%);
			}
		}

		@include breakpoint(medium) {
			width: auto;
			min-width: rem-calc(180);
		}

		&.is-active a {
			background-color: $blue-lighter-color;
			color: $blue-color;
			cursor: default;

			&:hover {
				background-color: $blue-lighter-color;
			}
		}
	}
}


// Tabs Content
.tabs-content {
	background-color: transparent;
	border: 0;
}









