// THUMBNALS


.thum-small {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	@include border-radius(30%);

	.inner {
		width: 100%;
		@include ratio1-1;
	}
}

.media-object {
	.thum {
		margin-top: 6px;
		width: 95px;
		border: 1px $medium-gray solid;

		.inner {
			@include ratio1-1;
			width: 100%;
		}
	}
}