// STAFF


.staff {
	text-align: center;
	list-style: none;
	margin: 0;
	padding-right: 0;
	text-align: center;
	//padding: 0 $spacer-normal;
	@include clearfix;

	// @include breakpoint(medium) {
	// 	padding: 0 $spacer-large;
	// }

	// @include breakpoint(large) {
	// 	padding: 0;
	// }

	.item {
		text-align: left;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		padding: rem-calc(15);
		margin-left: rem-calc(-2);
		margin-right: rem-calc(-2);

		@include breakpoint(medium) {
			width: 50%;
		}

		@include breakpoint(large) {
			width: 33.333%;
		}
	}

	.thum {
		display: block;
		width: 100%;
		margin-bottom: $spacer-small;
		@include box-shadow(4px);

		.inner {
			@include ratio3-2;		
		}
	}

	.info {
		display: block;
		padding-bottom: $spacer-small;

		.title,
		.subtitle {
			color: $black;
			margin: 0;
			padding: 0;
		}

		.subtitle {
			padding-bottom: .5em;
		}

		p {
			margin: 0;

			&.desc {
				color: $dark-gray;
			}
		}
	}
}


// .staff {
// 	text-align: center;
// 	list-style: none;
// 	margin: 0;
// 	padding-right: 0;
// 	text-align: center;
// 	@include clearfix;

// 	.item {
// 		display: inline-block;
// 		//float: left;
// 		width: 50%;
// 		padding: rem-calc(15);
// 		margin-left: rem-calc(-2);
// 		margin-right: rem-calc(-2);

// 		@include breakpoint(medium) {
// 			width: 33.333%;
// 		}

// 		@include breakpoint(large) {
// 			width: 16.666%;
// 		}

// 		a {
// 			display: block;
// 		}
// 	}

// 	.thum-small {
// 		max-width: rem-calc(100);
// 		margin-bottom: $spacer-small;
// 		@include box-shadow-full(0, 3px, 10px, $black, .35);

// 		@include breakpoint(medium) {
// 			max-width: rem-calc(120);
// 		}
// 	}

// 	.info {
// 		display: block;
// 		min-height: rem-calc(75);

// 		p {
// 			margin: 0;

// 			&.desc {
// 				color: $dark-gray;
// 			}
// 		}
// 	}
// }