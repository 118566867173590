// SPACER


.spacer {
	&-top {
		&-smaller 	{ padding-top: $spacer-smaller; }
		&-small 	{ padding-top: $spacer-small; }
		&-normal 	{ padding-top: $spacer-normal; }
		&-large 	{ padding-top: $spacer-large; }
		&-larger 	{ padding-top: $spacer-larger; }
	}

	&-right {
		&-smaller 	{ padding-right: $spacer-smaller; }
		&-small 	{ padding-right: $spacer-small; }
		&-normal 	{ padding-right: $spacer-normal; }
		&-large 	{ padding-right: $spacer-large; }
		&-larger 	{ padding-right: $spacer-larger; }
	}

	&-bottom {
		&-smaller 	{ padding-bottom: $spacer-smaller; }
		&-small 	{ padding-bottom: $spacer-small; }
		&-normal 	{ padding-bottom: $spacer-normal; }
		&-large 	{ padding-bottom: $spacer-large; }
		&-larger 	{ padding-bottom: $spacer-larger; }
	}

	&-left {
		&-smaller 	{ padding-left: $spacer-smaller; }
		&-small 	{ padding-left: $spacer-small; }
		&-normal 	{ padding-left: $spacer-normal; }
		&-large 	{ padding-left: $spacer-large; }
		&-larger 	{ padding-left: $spacer-larger; }
	}

	@include breakpoint(medium) {
		&-top {
			&-smaller 	{ padding-top: $spacer-smaller * 1.5; }
			&-small 	{ padding-top: $spacer-small * 2; }
			&-normal 	{ padding-top: $spacer-normal * 2; }
			&-large 	{ padding-top: $spacer-large * 2; }
			&-larger 	{ padding-top: $spacer-larger * 2; }
		}

		&-right {
			&-smaller 	{ padding-right: $spacer-smaller * 1.5; }
			&-small 	{ padding-right: $spacer-small * 2; }
			&-normal 	{ padding-right: $spacer-normal * 2; }
			&-large 	{ padding-right: $spacer-large * 2; }
			&-larger 	{ padding-right: $spacer-larger * 2; }
		}

		&-bottom {
			&-smaller 	{ padding-bottom: $spacer-smaller * 1.5; }
			&-small 	{ padding-bottom: $spacer-small * 2; }
			&-normal 	{ padding-bottom: $spacer-normal * 2; }
			&-large 	{ padding-bottom: $spacer-large * 2; }
			&-larger 	{ padding-bottom: $spacer-larger * 2; }
		}

		&-left {
			&-smaller 	{ padding-left: $spacer-smaller * 1.5; }
			&-small 	{ padding-left: $spacer-small * 2; }
			&-normal 	{ padding-left: $spacer-normal * 2; }
			&-large 	{ padding-left: $spacer-large * 2; }
			&-larger 	{ padding-left: $spacer-larger * 2; }
		}
	}
}

