// DEVELOPER


.developer {
	padding-top: 1.5em;
	text-align: center;

	a {
		display: inline-block;
		margin-top: 1em;
	}

	.mef-mw {
		font-size: 6em;
		line-height: 0;
	}

	@include breakpoint(large) {
		padding-top: 0;
	}
}
