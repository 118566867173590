// CONTACT US


.contact {
	.contact-info {
		text-align: center;
		
		@include breakpoint(medium) {
			text-align: left;
		}
	}
}