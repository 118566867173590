// CALL TODAY


.call-today {
	padding: $spacer-normal 0;
	background-color: $black;
	background-image: url(../img/call-us.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	text-align: center;

	@include breakpoint(medium) {
		padding: $spacer-large 0;
		background-position: right center;
		background-size: auto;
	}

	.row {
		& > div {
			position: relative;
			height: auto;

			@include breakpoint(medium) {
				height: 8em;
			}

			@include breakpoint(large) {
				height: 10em;
			}
			
			.inner {
				position: absolute;
				margin: auto 0;
			}
		}

		.text {
			padding: $spacer-normal 0;

			@include breakpoint(medium) {
				padding: 0;
			}
		}
	}

	.logo {
		background-color: $white;
		display: inline-block;
		padding: 1.5em;
		@include border-radius(50%);
		@include box-shadow(4px);

		@include breakpoint(large) {
			padding: 2em;
		}

		[class*="mef-"] {
			@include ratio1-1;
			font-size: 5em;

			@include breakpoint(large) {
				font-size: 6em;
			}
		}
	}

	p {
		padding: 0;
		margin: 0 0 5px 0;
		line-height: normal;

		&.h1 {
			font-size: 2.5em;

			@include breakpoint(medium) {
				font-size: 3em;
			}

			@include breakpoint(large) {
				font-size: 3.85em;
			}
		}
	}

	.button {
		margin: 0;
		@include box-shadow(4px);

		@include breakpoint(medium) {
			margin-top: 2.5em;
		}

		@include breakpoint(large) {
			margin-top: 3.5em;
			padding-left: 2.5em;
			padding-right: 2.5em;
		}
	}
}