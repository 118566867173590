// SEL EQUIPMENT


.sell-equipment {
	text-align: center;

	.top,
	.bottom {
		position: relative;
		display: block;
		margin: 0;
		padding: 0;
	}

	.svg {
		z-index: -1;
	}

	.top {
		background-color: $red-color;
		color: $white;
		z-index: 2;

		.triangle {
			fill: $red-color;
		}

		h3 {
			line-height: normal;
			padding: 9% $spacer-normal rem-calc(10) $spacer-normal;
			margin: 0;

			@include breakpoint(medium) {
				padding-top: 7%;
			}

			@include breakpoint(large) {
				padding-bottom: 0;
			}
		}
	}

	.bottom {
		padding: 10% 0 $spacer-normal 0;
		background-image: url(../img/sell-your-equipment-md.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		z-index: 1;

		@include breakpoint(medium) {
			padding: 8% 0 $spacer-large 0;
			background-image: url(../img/sell-your-equipment-lg.jpg);
		}

		p {
			font-size: $text-large;
			padding: 0 $spacer-small;
			color: $black;
		}

		.button {
			margin-top: $spacer-normal;
		}
	}
}