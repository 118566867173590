// REPLACEMENT PARTS


.replacement-parts {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	text-align: center;
	color: $white;
	z-index: 4;
	padding: rem-calc(10);

	.row {
		padding-top: $spacer-large;
	}

	.svg {
		z-index: -1;
	}

	.triangle {
		fill: $blue-color;
	}

	.button {
		margin-top: $spacer-small;

		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
}