// Download Financial Form


.download-finance-form {
	text-align: center;

	@include breakpoint(medium) {
	}

	@include breakpoint(large) {
	}

	.title {
		color: $blue-color;
		font-size: rem-calc(24);
		font-weight: bold;

		@include breakpoint(medium) {
			font-size: rem-calc(32);
		}

		@include breakpoint(large) {
		}
	}

	.button {
		width: 100%;
		padding: 0;
		padding-top: rem-calc(200);
		overflow: hidden;
		border: 0;
		background-image: url(../img/download-finance.jpg);
		background-position: center top;

		@include breakpoint(medium) {
			max-width: 640px;
		}

		@include breakpoint(large) {
		}

		.white {
			background-color: $white;
			display: block;
			padding: $button-padding;
		}
	}
}