// HADER MENUS


.header-menu {
	ul {
		margin: 0;
		padding: 0;
		display: block;

		li {
			border-top: 1px solid rgba( $blue-light-color, .25 );
			display: block;
			width: 100%;
		}
	}

	a {
		display: block;
		width: 100%;
		line-height: rem-calc(50);
		padding: 0 rem-calc(15);

		&:hover {
			background-color: $white;
		}
	}

	.menu-title {
		border: 0;
		line-height: rem-calc(50);
		padding: 0 rem-calc(15);
		text-transform: uppercase;
		color: $blue-light-color;
	}
}





















