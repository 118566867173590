// Add This


.addthis {
	position: relative;
	text-align: center;

	@include breakpoint(large) {
		position: absolute;
		display: table;
		right: rem-calc(15);
		margin-top: rem-calc(15);
	}

	.addthis_sharing_toolbox {
		display: inline-block;
	}

	.literal,
	.addthis_sharing_toolbox {
		@include breakpoint(large) {
			display: table-cell;
			vertical-align: middle;
			padding-left: 1em;
		}
	}

}