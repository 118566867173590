// FORMS


form {
	input {
		&[type="buton"],
		&[type="submit"],
		&[type="reset"] {
			width: 100%;

			@include breakpoint(medium) {
				width: auto;
				min-width: rem-calc(120);
			}
		}
	}
}